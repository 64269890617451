const {
  VITE_USE_SENTRY,
  VITE_BACKEND_URL: BACKEND_URL,
  VITE_GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY,
  VITE_SENTRY_DSN: SENTRY_DSN,
  MODE
} = import.meta.env;
const USE_SENTRY = VITE_USE_SENTRY === 'true';

export { USE_SENTRY, BACKEND_URL, GOOGLE_MAPS_API_KEY, SENTRY_DSN, MODE };
