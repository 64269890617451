import ErrorDisplay from './ErrorDisplay';

interface ErrorComponentProps {
  message?: string;
}

const ErrorNotFound404: React.FC<ErrorComponentProps> = ({ message }) => (
  <ErrorDisplay statusCode={404} defaultMessage="The page you are looking for does not exist." message={message} />
);

const ErrorServer500: React.FC<ErrorComponentProps> = ({ message }) => (
  <ErrorDisplay statusCode={500} defaultMessage="Internal server error. Please try again later." message={message} />
);

export { ErrorNotFound404, ErrorServer500 };
