import { Box, Container, Grid, GridItem, Heading, Text, List, ListItem, ListIcon } from '@chakra-ui/react';

import { MdNavigateNext } from 'react-icons/md';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useSelector } from 'react-redux';
import { useGetProfileStatsQuery } from '@services/canaria.services';
import Loading from '@features/loading/Loading.component';

interface BoxTemplateProps {
  title: string;
  children: React.ReactNode;
}

const Dashboard: React.FC = () => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('No active org ID found');
  }

  return (
    <Container maxW="8xl">
      <Heading as="h1" size="lg" textAlign="center" mb={5}>
        Dashboard
      </Heading>
      <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 1fr)" gap={6} height="80vh">
        <GridItem colSpan={1} rowSpan={1} h="100%" bg="white">
          <AlertsBox />
        </GridItem>
        <GridItem colSpan={1} rowSpan={1} h="100%" bg="white">
          <ProfilesBox orgId={activeOrgID} />
        </GridItem>
      </Grid>
    </Container>
  );
};

const BoxTemplate: React.FC<BoxTemplateProps> = ({ title, children }) => {
  return (
    <Box p={5} boxShadow="md" borderRadius="md" borderWidth="1px" height="100%">
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        {title}
      </Text>
      {children}
    </Box>
  );
};

const AlertsBox: React.FC = () => {
  const listItems = ['Wallet screening', 'Party screening'];
  return (
    <BoxTemplate title="Open alerts by type">
      <List spacing={3}>
        {listItems.map((item, index) => (
          <ListItem key={index}>
            <ListIcon as={MdNavigateNext} color="green.500" />
            {item}
          </ListItem>
        ))}
      </List>
    </BoxTemplate>
  );
};

enum ProfileStatusMapping {
  A = 'Archived',
  C = 'Completed',
  D = 'Draft'
}

const ProfilesBox: React.FC<{
  orgId: string;
}> = ({ orgId }) => {
  const { data, isLoading } = useGetProfileStatsQuery({
    orgId
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BoxTemplate title="Profiles by status">
      <List spacing={3}>
        {data.map((item, index) => (
          <ListItem key={index}>
            <ListIcon as={MdNavigateNext} color="green.500" />
            {`${ProfileStatusMapping[item.status]}: ${item.total}`}
          </ListItem>
        ))}
      </List>
    </BoxTemplate>
  );
};

export default Dashboard;
