import { useEffect } from 'react';
import { type ChangeEvent, type DragEvent, useState } from 'react';
import { useUploadDocumentMutation, useGetSignedUrlQuery } from '@services/canaria.services';

import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  VStack
} from '@chakra-ui/react';

interface ScanIDModalProps {
  isOpen: boolean;
  onClose: () => void;
  orgId: string;
  profileId: string;
  setIsFileProcessing: (isProcessing: boolean) => void;
}

const ScanIDModal: React.FC<ScanIDModalProps> = ({ isOpen, onClose, orgId, profileId, setIsFileProcessing }) => {
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [triggerSignedUrlFetch, setTriggerSignedUrlFetch] = useState(false);
  const [filename, setFilename] = useState<string>('');
  const [uploadDocument] = useUploadDocumentMutation();
  const { data: signedUrlData } = useGetSignedUrlQuery(
    { orgId, profileId, filename, mimeType: file?.type },
    { skip: !triggerSignedUrlFetch || filename == null }
  );

  useEffect(() => {
    const init = async (): Promise<void> => {
      if (signedUrlData != null && triggerSignedUrlFetch) {
        setTriggerSignedUrlFetch(false);
        try {
          setIsFileProcessing(true);
          if (file != null) {
            const response = await fetch(signedUrlData.signedUrl, {
              method: 'PUT',
              body: file,
              headers: {
                'Content-Type': file.type
              }
            });
            if (!response.ok) {
              throw new Error('Upload failed');
            }
            await uploadDocument({
              orgId,
              profileId,
              key: signedUrlData.key,
              profileAttachmentType: 'ProfileIdDocument'
            }).unwrap();
            setIsFileProcessing(false);
          }
        } catch (error) {
          console.error('File upload failed', error);
        } finally {
          setIsFileProcessing(false);
        }
      }
    };
    void init();
  }, [signedUrlData, triggerSignedUrlFetch, orgId, profileId, uploadDocument, file, setIsFileProcessing]);

  const updateFileAndPreview = (newFile: File | null): void => {
    if (newFile != null) {
      const url = URL.createObjectURL(newFile);
      setPreviewUrl(url);
      setFile(newFile);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target?.files == null) return;
    updateFileAndPreview(event.target.files[0]);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    updateFileAndPreview(event.dataTransfer.files[0]);
  };

  const handlePaste = (event: React.ClipboardEvent): void => {
    const items = event.clipboardData?.items ?? event.nativeEvent.clipboardData?.items;

    for (const item of items) {
      if (item.kind === 'file') {
        updateFileAndPreview(item.getAsFile());
        break;
      }
    }
  };

  const handleClose = (): void => {
    // Revoke the object URL to avoid memory leaks
    if (previewUrl != null) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
    onClose();
  };

  const handleProcess = async (): Promise<void> => {
    if (file == null) {
      console.error('No file selected for upload.');
      return;
    }
    setFilename(file.name);
    setTriggerSignedUrlFetch(true);

    handleClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload & Scan ID</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Input type="file" onChange={handleFileChange} />
            <Box
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onPaste={handlePaste}
              style={{
                width: '100%',
                height: '100px',
                border: '1px dashed gray',
                position: 'relative',
                padding: '10px'
              }}
            >
              Drop file here or paste from clipboard
              {previewUrl != null && (
                <Image
                  src={previewUrl}
                  alt="Preview"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100px',
                    position: 'absolute',
                    top: '0',
                    left: '0'
                  }}
                />
              )}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleProcess} isDisabled={file == null}>
            Process
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ScanIDModal;
