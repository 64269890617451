import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button as MaterialButton, ThemeProvider, createTheme } from '@mui/material';
import { PROFILE_TYPE } from '@models/profileTypes';

import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';

interface RelatedPartyListProps {
  isLoadingRelatedProfiles: boolean;
  relatedProfiles: any[];
  onClickEdit: (party: any) => void;
  onClickAdd: () => void;
}

const RelatedPartyList: React.FC<RelatedPartyListProps> = ({
  relatedProfiles,
  isLoadingRelatedProfiles,
  // onClickEdit,
  onClickAdd
}) => {
  const defaultMaterialTheme = createTheme();

  const columns = useMemo<Array<MRT_ColumnDef<any>>>(
    () => [
      {
        id: 'id',
        header: 'ID',
        accessorKey: 'profile.id'
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'profile.fullLegalName'
      },
      {
        id: 'resourcetype',
        header: 'Type',
        accessorKey: 'profile.resourcetype',
        Cell: ({ row }) => {
          return row.original.profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Entity';
        }
      },
      {
        id: 'relationship',
        header: 'Relationship to primary Party',
        accessorKey: 'relationType.name'
      },
      {
        id: 'view',
        header: '',
        accessorKey: 'view',
        Cell: ({ row }) => (
          <MaterialButton
            component={Link}
            to={`/dashboard/profiles/${row.original.profile.id}`}
            variant="contained"
            color="primary"
            size="small"
          >
            View
          </MaterialButton>
        )
      }
      /*
      {
        id: 'edit',
        header: 'Edit',
        accessorKey: 'edit',
        Cell: ({ row }) => (
          <MaterialButton
            variant="contained"
            onClick={() => {
              onClickEdit(row.original);
            }}
          >
            Edit
          </MaterialButton>
        )
      }
      */
    ],
    []
  );
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialReactTable
        columns={columns}
        data={relatedProfiles ?? []}
        renderTopToolbarCustomActions={() => {
          return (
            <MaterialButton
              variant="contained"
              onClick={() => {
                onClickAdd();
              }}
            >
              Register new related party
            </MaterialButton>
          );
        }}
        state={{
          isLoading: isLoadingRelatedProfiles
        }}
      />
    </ThemeProvider>
  );
};

export default RelatedPartyList;
