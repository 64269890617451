import { MODE } from './env';
import analyticsInstance from './features/analytics/analytics';
const reportWebVitals = (): void => {
  // running it in development will cause a lot of noise
  // and also it crashes the vite dev server
  if (['production', 'staging'].includes(MODE)) {
    import('web-vitals')
      .then(({ onCLS, onINP, onLCP, onFCP, onTTFB }) => {
        onCLS((metric) => {
          analyticsInstance.trackWebVital({ name: 'CLS', value: metric.value, rating: metric.rating });
        });
        onINP((metric) => {
          analyticsInstance.trackWebVital({ name: 'INP', value: metric.value, rating: metric.rating });
        });
        onLCP((metric) => {
          analyticsInstance.trackWebVital({ name: 'LCP', value: metric.value, rating: metric.rating });
        });
        onFCP((metric) => {
          analyticsInstance.trackWebVital({ name: 'FCP', value: metric.value, rating: metric.rating });
        });
        onTTFB((metric) => {
          analyticsInstance.trackWebVital({ name: 'TTFB', value: metric.value, rating: metric.rating });
        });
      })
      .catch(() => {
        console.error('Error importing web vitals.');
      });
  }
};

export default reportWebVitals;
