import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { Field, FormSpy } from 'react-final-form';

import { FormControl, FormErrorMessage, FormLabel, Flex, HStack, Input, Select } from '@chakra-ui/react';

import {
  useGetCountriesQuery,
  useGetNatureOfEmploymentQuery,
  useGetEntityTypesQuery
} from '@services/canaria.services';

import { USA_STATES } from '../../../utils/consts';
import PlacePicker from '@features/google-places/PlacePicker';
import { extractAddressDetails } from '../../../utils/googlePlaces';
import { validateEmail } from '@services/utils';

export const IndividualPrimaryPartyForm: React.FC<{ form: any }> = ({ form }) => {
  const { data: countries } = useGetCountriesQuery(null);
  const { data: natureOfEmploymentList } = useGetNatureOfEmploymentQuery(null);
  const hundredYearsAgo = new Date();
  hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);

  return (
    <>
      <Flex gap={4}>
        <FormControl id="fullLegalName" isRequired>
          <FormLabel>Full Legal Name</FormLabel>
          <Field
            name="fullLegalName"
            render={({ input }) => <Input {...input} placeholder="Enter full legal name" />}
          />
        </FormControl>

        <Field
          name="email"
          validate={(value) => {
            if (value != null && value !== '' && !validateEmail(value)) {
              return 'Invalid email address';
            }
            return undefined;
          }}
          render={({ input, meta }) => (
            <FormControl id="email" isInvalid={meta.touched === true && meta.error != null}>
              <FormLabel>Email</FormLabel>
              <Input {...input} placeholder="Enter email" />
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        />
      </Flex>
      <Flex gap={4}>
        <HStack flex="1">
          <FormControl>
            <FormLabel htmlFor="gender">Gender</FormLabel>
            <Field name="gender" parse={(value) => value ?? ''}>
              {({ input }) => (
                <Select {...input} id="gender">
                  <option value="">Select gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </Select>
              )}
            </Field>
          </FormControl>

          <FormControl id="dateOfBirth" isRequired>
            <FormLabel>Date of Birth</FormLabel>
            <Flex w="100%">
              <Field name="dateOfBirth">
                {({ input }) => (
                  <DatePicker
                    {...input}
                    onChange={(date) => {
                      input.onChange(date != null ? dayjs(date).format('YYYY-MM-DD') : null);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    maxDate={new Date()}
                    minDate={hundredYearsAgo}
                    dateFormat="dd/MM/yyyy"
                    customInput={<Input w="100%" />}
                    placeholderText="Select a date"
                    dropdownMode="select"
                  />
                )}
              </Field>
            </Flex>
          </FormControl>
        </HStack>
        <FormControl id="citizenship" isRequired flex="1">
          <FormLabel htmlFor="citizenship">Citizenship</FormLabel>
          <Field name="citizenship">
            {({ input }) => (
              <Select {...input} placeholder="Select citizenship">
                {countries?.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.englishName}
                  </option>
                ))}
              </Select>
            )}
          </Field>
        </FormControl>
      </Flex>
      <Flex gap={4}>
        <HStack flex="1">
          <FormControl id="address" flex="1">
            <FormLabel htmlFor="address">Address</FormLabel>
            <Field
              name="address"
              render={({ input }) => (
                <PlacePicker
                  input={input}
                  onPlaceSelected={(place) => {
                    const placeDetails = extractAddressDetails(place);
                    if (placeDetails.country !== '') {
                      const country = countries?.find((c) => c.alpha2 === placeDetails.country);
                      if (country != null) form.mutators.setValue('countryOfResidence', String(country.id));
                    }
                    if (placeDetails.city !== '') form.mutators.setValue('city', placeDetails.city);
                    if (placeDetails.state !== '') form.mutators.setValue('state', placeDetails.state);
                    if (placeDetails.postalCode !== '') form.mutators.setValue('postalCode', placeDetails.postalCode);
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl id="countryOfResidence" isRequired flex="1">
            <FormLabel htmlFor="countryOfResidence">Country of Residence</FormLabel>
            <Field name="countryOfResidence">
              {({ input }) => (
                <Select {...input} placeholder="Select country">
                  {countries?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.englishName}
                    </option>
                  ))}
                </Select>
              )}
            </Field>
          </FormControl>
        </HStack>
        <HStack flex="1">
          <FormControl id="state" flex="2">
            <FormLabel htmlFor="state">State/Department</FormLabel>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                return (
                  <Field name="state">
                    {({ input }) => {
                      const countryOfResidence = values.countryOfResidence;
                      const countryObj = countries?.find((c) => String(c.id) === countryOfResidence);
                      if (countryObj?.alpha3 === 'USA') {
                        return (
                          <Select {...input} placeholder="Select state">
                            {USA_STATES.map((state) => (
                              <option key={state} value={state}>
                                {state}
                              </option>
                            ))}
                          </Select>
                        );
                      } else {
                        return <Input {...input} placeholder="Enter state" />;
                      }
                    }}
                  </Field>
                );
              }}
            </FormSpy>
          </FormControl>
          <FormControl flex="2">
            <FormLabel htmlFor="city">City</FormLabel>
            <Field name="city" render={({ input }) => <Input {...input} id="city" placeholder="Enter city" />} />
          </FormControl>
          <FormControl flex="1">
            <FormLabel htmlFor="postalCode">Postal Code</FormLabel>
            <Field
              name="postalCode"
              render={({ input }) => <Input {...input} id="postalCode" placeholder="" maxLength={8} />}
            />
          </FormControl>
        </HStack>
      </Flex>
      <Flex gap={4}>
        <HStack flex="1">
          {/* ID Issuer/Country */}
          <FormControl id="idIssuer" flex="1">
            <FormLabel htmlFor="idIssuer">ID Issuer/Country</FormLabel>
            <Field name="idIssuer">
              {({ input }) => (
                <Select {...input} id="idIssuer" placeholder="Select country">
                  {countries?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.englishName}
                    </option>
                  ))}
                </Select>
              )}
            </Field>
          </FormControl>
          <FormControl isRequired flex="1">
            <FormLabel htmlFor="idType">ID Type</FormLabel>
            <Field name="idType">
              {({ input }) => (
                <Select {...input} id="id-type" placeholder="Select ID type">
                  <option value="P">Passport</option>
                  <option value="N">National ID</option>
                  <option value="D">Driver License</option>
                  <option value="O">Other</option>
                </Select>
              )}
            </Field>
          </FormControl>
          <FormControl id="idNumber" isRequired flex="1">
            <FormLabel htmlFor="idNumber">ID Number</FormLabel>
            <Field
              name="idNumber"
              render={({ input }) => <Input {...input} id="idNumber" placeholder="Enter ID number" />}
            />
          </FormControl>
        </HStack>
        <FormControl id="placeOfBirth" flex="1">
          <FormLabel htmlFor="placeOfBirth">Place of Birth</FormLabel>
          <Field name="placeOfBirth">
            {({ input }) => (
              <Select {...input} placeholder="Select country">
                {countries?.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.englishName}
                  </option>
                ))}
              </Select>
            )}
          </Field>
        </FormControl>
      </Flex>
      <FormControl>
        <FormLabel htmlFor="natureOfEmployment">Nature of Employment</FormLabel>
        <Field
          name="natureOfEmployment"
          render={({ input }) => (
            <Select {...input} id="natureOfEmployment" placeholder="Select nature of employment">
              {natureOfEmploymentList?.map(({ value, name }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </>
  );
};

export const EntityPrimaryPartyForm: React.FC = () => {
  const { data: countries } = useGetCountriesQuery(null);
  const { data: entityTypes } = useGetEntityTypesQuery(null);

  const hundredYearsAgo = new Date();
  hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);
  return (
    <>
      <Flex gap={4}>
        <FormControl id="fullLegalName" isRequired>
          <FormLabel>Full Legal Name</FormLabel>
          <Field
            name="fullLegalName"
            render={({ input }) => <Input {...input} placeholder="Enter full legal name" />}
          />
        </FormControl>
        <FormControl id="dba">
          <FormLabel>DBA/Trade Name</FormLabel>
          <Field name="dbaTradeName" render={({ input }) => <Input {...input} placeholder="" />} />
        </FormControl>
      </Flex>
      <Flex gap={4}>
        <FormControl isRequired>
          <FormLabel htmlFor="entityType">Entity Type</FormLabel>
          <Field name="entityType">
            {({ input }) => (
              <Select {...input} id="entityType" placeholder="Select entity type">
                {entityTypes?.map((entityType) => (
                  <option key={entityType.value} value={entityType.value}>
                    {entityType.name}
                  </option>
                ))}
              </Select>
            )}
          </Field>
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="placeOfEstablishment">Place of Establishment</FormLabel>
          <Field name="placeOfEstablishment">
            {({ input }) => (
              <Select {...input} id="placeOfEstablishment" placeholder="Select place of establishment">
                {countries?.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.englishName}
                  </option>
                ))}
              </Select>
            )}
          </Field>
        </FormControl>
        <FormControl id="dateOfEstablishment" isRequired>
          <FormLabel>Date of Establishment</FormLabel>
          <Flex w="100%">
            <Field name="dateOfEstablishment">
              {({ input }) => (
                <DatePicker
                  {...input}
                  onChange={(date) => {
                    input.onChange(date != null ? dayjs(date).format('YYYY-MM-DD') : null);
                  }}
                  showMonthDropdown
                  showYearDropdown
                  maxDate={new Date()}
                  minDate={hundredYearsAgo}
                  dateFormat="dd/MM/yyyy"
                  customInput={<Input w="100%" />}
                  placeholderText="Select a date"
                  dropdownMode="select"
                />
              )}
            </Field>
          </Flex>
        </FormControl>
        <FormControl id="uniqueIdentificationNumber" isRequired>
          <FormLabel>Unique Identification Number</FormLabel>
          <Field
            name="uniqueIdentificationNumber"
            render={({ input }) => <Input {...input} placeholder="Enter unique identification number" />}
          />
        </FormControl>
      </Flex>
      <Flex gap={4}>
        <FormControl id="registeredBusinessAddress" isRequired>
          <FormLabel htmlFor="registeredBusinessAddress">Registered Business Address</FormLabel>
          <Field
            name="registeredBusinessAddress"
            render={({ input }) => <PlacePicker input={input} completeAddress={true} />}
          />
        </FormControl>
      </Flex>
      <Flex gap={4}>
        <FormControl id="mailingAddress">
          <FormLabel htmlFor="mailingAddress">Mailing Address</FormLabel>
          <Field name="mailingAddress" render={({ input }) => <PlacePicker input={input} completeAddress={true} />} />
        </FormControl>
      </Flex>
      <FormControl>
        <FormLabel htmlFor="physicalAddress">Physical Business Address</FormLabel>
        <Field name="physicalAddress" render={({ input }) => <PlacePicker input={input} completeAddress={true} />} />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="companyWebsite">Company Website</FormLabel>
        <Field
          name="companyWebsite"
          render={({ input }) => <Input {...input} id="companyWebsite" placeholder="Enter company website" />}
        />
      </FormControl>
    </>
  );
};
