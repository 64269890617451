import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Input,
  Text,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel
} from '@chakra-ui/react';
import { useRegisterWalletMutation } from '@services/canaria.services';

interface RegisterWalletModalProps {
  address: string;
  orgId: string;
  onClose: () => void;
  isOpen: boolean;
}

const RegisterWalletModal: React.FC<RegisterWalletModalProps> = (props) => {
  const [formState, setFormState] = useState({
    name: '',
    address: props.address,
    notes: ''
  });

  const handleChange = ({ target: { name, value } }): void => {
    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  const nameIsError = formState.name === '';
  const addressIsError = formState.address === '';

  const [registerWallet, { isLoading }] = useRegisterWalletMutation();
  const navigate = useNavigate();
  const toast = useToast();

  const handleClick = async (e): Promise<void> => {
    try {
      e.preventDefault();
      const params = {
        orgId: props.orgId,
        name: formState.name,
        address: formState.address,
        notes: formState.notes
      };
      const result = await registerWallet(params).unwrap();
      props.onClose();
      navigate('/dashboard/wallets/' + result.id);
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Error while trying to register wallet',
        isClosable: true
      });
    }
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Register Blockchain Address</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="sm" as="i">
            In order to search for detailed information of a specific blockchain address, the system needs to register
            it first.
          </Text>
          <br />
          <br />
          <hr />
          <br />
          <FormControl isRequired>
            <FormLabel>Name (required)</FormLabel>
            <Input
              isInvalid={nameIsError}
              type="text"
              placeholder="Use a descriptive name that will be searchable"
              onChange={handleChange}
              name="name"
            />
            <FormLabel>Address (required)</FormLabel>
            <Input
              isInvalid={addressIsError}
              type="text"
              placeholder="Blockchain Address"
              onChange={handleChange}
              name="address"
              defaultValue={props.address}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Notes</FormLabel>
            <Textarea onChange={handleChange} name="notes" />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="green"
            bg="green.400"
            mr={3}
            isDisabled={nameIsError || addressIsError}
            isLoading={isLoading}
            onClick={handleClick}
          >
            Register
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterWalletModal;
