import mixpanel from 'mixpanel-browser';
import { USE_SENTRY } from '../../env';

const MIXPANEL_KEY = '1a29aa55448ee2a3e3b1d953a314dff9';
let instance: Analytics | null = null;
class Analytics {
  constructor() {
    if (instance != null) throw new Error('New instance cannot be created!!');
    if (USE_SENTRY) {
      mixpanel.init(MIXPANEL_KEY);
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    instance = this;
  }

  trackLoggedIn(): void {
    if (!USE_SENTRY) return;
    mixpanel.track('Logged In');
  }

  trackLoggedOut(): void {
    if (!USE_SENTRY) return;
    mixpanel.track('Logged Out');
    mixpanel.reset();
  }

  trackIdentify(email: string, firstName: string, lastName: string, created): void {
    if (!USE_SENTRY) return;
    mixpanel.identify(email);
    mixpanel.register({
      Email: 'user',
      'First Name': firstName,
      'Last Name': lastName
    });
    mixpanel.people.set({
      $email: email,
      $first_name: firstName,
      $last_name: lastName,
      $distinct_id: email,
      $created: created
    });
  }

  trackOrganization(orgId: string | undefined, orgName: string): void {
    if (!USE_SENTRY) return;
    mixpanel.register({
      'Organization ID': orgId,
      'Organization Name': orgName
    });
  }

  trackWalletInquiry(walletAddress: string, walletName: string): void {
    if (!USE_SENTRY) return;
    mixpanel.track('Wallet Inquiry', {
      'Wallet Address': walletAddress,
      'Wallet Name': walletName
    });
  }

  trackAlertStatusChange(params: { alertId: string; opened: boolean }): void {
    if (!USE_SENTRY) return;
    if (params.opened) {
      mixpanel.track('Alert Opened', {
        'Alert ID': params.alertId
      });
    } else {
      mixpanel.track('Alert Closed', {
        'Alert ID': params.alertId
      });
    }
  }

  trackWebVital(metric: { name: string; value: number; rating: string }): void {
    if (!USE_SENTRY) return;
    mixpanel.track(metric.name, {
      value: metric.value,
      rating: metric.rating
    });
  }
}

const analyticsInstance = Object.freeze(new Analytics());

export default analyticsInstance;
