import { Box, chakra, Container, Stack, Text, useColorModeValue, VisuallyHidden } from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaRegNewspaper } from 'react-icons/fa';

interface SocialButtonProps {
  children: React.ReactNode;
  label: string;
  href: string;
}

const SocialButton: React.FC<SocialButtonProps> = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded="full"
      w={8}
      h={8}
      cursor="pointer"
      as="a"
      href={href}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="background 0.3s ease"
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200')
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      {/* this box fills out the remaining space of the page */}
      <Box flexGrow={1} flexShrink={0}></Box>
      <Box
        className="footer"
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}
      >
        <Container
          as={Stack}
          maxW="6xl"
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}
        >
          <Text>© 2023-{currentYear} Canaria Consulting. All rights reserved</Text>
          <Stack direction="row" spacing={6}>
            <SocialButton label="Website" href="https://www.canariaconsulting.com/">
              <FaRegNewspaper />
            </SocialButton>
            <SocialButton label="Twitter" href="https://twitter.com/CanariaCo">
              <FaTwitter />
            </SocialButton>
            <SocialButton label="Instagram" href="https://www.instagram.com/canariaco/">
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
