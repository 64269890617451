import { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Select,
  Grid,
  Text,
  VStack,
  HStack,
  useDisclosure,
  IconButton,
  Tooltip
} from '@chakra-ui/react';
import { FaMagic } from 'react-icons/fa';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { CloseIcon } from '@chakra-ui/icons';

import {
  useGetCountriesQuery,
  useGetGendersQuery,
  useGetProfileRiskRatingsQuery,
  useGetIdTypesQuery,
  useGetEntityTypesQuery,
  useGetNatureOfEmploymentQuery,
  useGetProfileByIdQuery,
  useGetProfileGroupsQuery,
  useGetProfileInquiriesQuery,
  useUpdateProfileMutation,
  useDeleteDocumentMutation
} from '@services/canaria.services';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import AlertBox from './AlertBox';
import EditableField from './EditableField';
import RelatedPartiesSection from './RelatedPartiesSection';
import { WalletsTable } from '@features/panels/walletsPanel/WalletsPanel.component';
import ProfileGroupOverview from './ProfileGroupOverview';
import ProfileNotes from './ProfileNotes';
import ProfileAudit from './ProfileAudit';
import { PROFILE_STATUS_CODE, PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import {
  REGULATORY_INVESTIGATION_STATUS_OPTIONS,
  LICENSING_REGISTRATION_STATUS_OPTIONS,
  AML_PROGRAM_REQUIRED_OPTIONS,
  AML_PROGRAM_ELEMENTS_OPTIONS,
  SANCTIONS_PROGRAM_REQUIRED_OPTIONS,
  SANCTIONS_PROGRAM_ELEMENTS_OPTIONS
} from '../ProfileWizard/entity/entityOptions';
import AttachmentModal from '../ProfileWizard/common/AddAttachmentModal';
import ConfirmationModal from './ConfirmationModal';
import Loading from '@features/loading/Loading.component';

const formatDate = (date: string): string => {
  if (date == null) return '';
  const dateObj = parseISO(date);
  return format(dateObj, 'yyyy-MM-dd HH:mm');
};

const canChangeStatus = (
  currentStatus: PROFILE_STATUS_CODE,
  targetStatus: PROFILE_STATUS_CODE,
  hasOpenItems: boolean
): boolean => {
  if (currentStatus === targetStatus) {
    return false;
  }
  if (currentStatus === PROFILE_STATUS_CODE.DRAFT && targetStatus === PROFILE_STATUS_CODE.COMPLETE && hasOpenItems) {
    return false;
  }

  if (currentStatus === PROFILE_STATUS_CODE.ARCHIVED && targetStatus === PROFILE_STATUS_CODE.COMPLETE && hasOpenItems) {
    return false;
  }

  if (currentStatus === PROFILE_STATUS_CODE.COMPLETE && targetStatus === PROFILE_STATUS_CODE.DRAFT) {
    return false;
  }

  return true;
};

const PrimaryPartyInformationEntity: React.FC<{ profile: IProfile }> = ({ profile }) => {
  const activeOrgID = useSelector(selectActiveOrgID);

  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  const [updateProfile] = useUpdateProfileMutation();
  const { data: countries = [] } = useGetCountriesQuery(null);
  const { data: entityTypes = [] } = useGetEntityTypesQuery(null);
  const mappedCountries = countries.map((country) => ({
    value: country.id,
    name: country.englishName
  }));

  const handleFieldChange = (name: string) => async (value) => {
    await updateProfile({
      orgId: activeOrgID,
      profileId: profile.id,
      [name]: value
    }).unwrap();
  };

  return (
    <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
      <VStack spacing={6}>
        <EditableField
          label="Full Legal Name"
          value={profile?.fullLegalName}
          onConfirmChange={handleFieldChange('fullLegalName')}
        />
        <EditableField
          label="DBA/Trade Name"
          value={profile?.dbaTradeName}
          onConfirmChange={handleFieldChange('dbaTradeName')}
        />
        <EditableField
          label="Entity Type"
          value={profile?.entityType}
          type="select"
          onConfirmChange={handleFieldChange('entityType')}
          options={entityTypes}
        />
        <EditableField
          label="Place of Establishment"
          value={profile?.placeOfEstablishment?.id}
          onConfirmChange={handleFieldChange('placeOfEstablishment')}
          type="select"
          options={mappedCountries}
        />
        <EditableField
          label="Date of Establishment"
          value={profile?.dateOfEstablishment}
          onConfirmChange={handleFieldChange('dateOfEstablishment')}
          type="date"
        />
        <EditableField
          label="Unique Identification Number"
          value={profile?.uniqueIdentificationNumber}
          onConfirmChange={handleFieldChange('uniqueIdentificationNumber')}
        />
        <EditableField
          label="Registered Business Address"
          value={profile?.registeredBusinessAddress}
          onConfirmChange={handleFieldChange('registeredBusinessAddress')}
        />
        <EditableField
          label="Mailing Address"
          value={profile?.mailingAddress}
          onConfirmChange={handleFieldChange('mailingAddress')}
        />
        <EditableField
          label="Physical Address"
          value={profile?.physicalAddress}
          onConfirmChange={handleFieldChange('physicalAddress')}
        />
        <EditableField
          label="Company website"
          value={profile?.companyWebsite}
          onConfirmChange={handleFieldChange('companyWebsite')}
        />
      </VStack>
      {profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Business Regulatory Compliance')
        ?.entity === true && (
        <VStack spacing={6}>
          <EditableField
            label="Nature of Business"
            value={profile?.natureOfBusiness}
            onConfirmChange={handleFieldChange('natureOfBusiness')}
          />
          <EditableField
            label="Regulatory Investigation Status"
            value={profile?.regulatoryInvestigationStatus}
            onConfirmChange={handleFieldChange('regulatoryInvestigationStatus')}
            type="select"
            options={REGULATORY_INVESTIGATION_STATUS_OPTIONS.map((option) => ({
              value: option.value,
              name: option.label
            }))}
          />
          <EditableField
            label="Licensing/Registration Status"
            value={profile?.licensingRegistrationStatus}
            onConfirmChange={handleFieldChange('licensingRegistrationStatus')}
            type="select"
            options={LICENSING_REGISTRATION_STATUS_OPTIONS.map((option) => ({
              value: option.value,
              name: option.label
            }))}
          />
          <EditableField
            label="Licensing/Registration Information"
            value={profile?.licensingRegistrationInformation}
            onConfirmChange={handleFieldChange('licensingRegistrationInformation')}
          />
          <EditableField
            label="AML Program Required?"
            value={Number(profile?.amlProgramRequired)}
            onConfirmChange={handleFieldChange('amlProgramRequired')}
            type="select"
            options={AML_PROGRAM_REQUIRED_OPTIONS.map((option) => ({
              value: Number(option.value),
              name: option.label
            }))}
          />
          <EditableField
            label="AML Program Elements"
            value={profile?.amlProgramElements}
            onConfirmChange={handleFieldChange('amlProgramElements')}
            type="select"
            options={AML_PROGRAM_ELEMENTS_OPTIONS.map((option) => ({
              value: option.value,
              name: option.label
            }))}
          />
          <EditableField
            label="Sanctions Program Required?"
            value={Number(profile?.sanctionsProgramRequired)}
            onConfirmChange={handleFieldChange('sanctionsProgramRequired')}
            type="select"
            options={SANCTIONS_PROGRAM_REQUIRED_OPTIONS.map((option) => ({
              value: Number(option.value),
              name: option.label
            }))}
          />
          <EditableField
            label="Sanctions Program Elements"
            value={profile?.sanctionsProgramElements}
            onConfirmChange={handleFieldChange('sanctionsProgramElements')}
            type="select"
            options={SANCTIONS_PROGRAM_ELEMENTS_OPTIONS.map((option) => ({
              value: option.value,
              name: option.label
            }))}
          />
          <EditableField
            label="AML Program comments"
            value={profile?.amlProgramComments}
            onConfirmChange={handleFieldChange('amlProgramComments')}
          />
        </VStack>
      )}
    </Grid>
  );
};

const Attachments: React.FC<{
  profileAttachment?: any | null;
  attachments: Array<{ id: string; file: string; name: string; notes: string }>;
  orgId: string;
  profileId: string;
}> = ({ profileAttachment, attachments, orgId, profileId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [deleteDocument] = useDeleteDocumentMutation();
  const [selectedAttachment, setSelectedAttachment] = useState<{ id: string; name: string } | null>(null);

  const allAttachments = [profileAttachment, ...attachments].filter((attachment) => attachment != null);

  const handleDelete = async (): Promise<void> => {
    if (selectedAttachment != null) {
      await deleteDocument({
        orgId,
        profileId,
        documentId: selectedAttachment.id
      });
      onDeleteClose();
    }
  };

  return (
    <Heading as="h1" size="md" textAlign="left" mb={1}>
      Attachments
      <Flex direction="column">
        {allAttachments.map((attachment, index) => (
          <VStack align="flex-start" key={attachment.id} mb={2}>
            <HStack>
              <Text fontSize="sm" color="blue">
                {index + 1}.{' '}
                <a href={attachment.file} style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">
                  {index === 0 && profileAttachment != null ? 'ID Document' : attachment.name}
                </a>{' '}
              </Text>
              {((profileAttachment != null && index > 0) || profileAttachment == null) && (
                <Tooltip label="Delete attachment" aria-label="Delete attachment tooltip">
                  <IconButton
                    aria-label="Delete attachment"
                    icon={<CloseIcon />}
                    size="xs"
                    colorScheme="gray"
                    onClick={() => {
                      setSelectedAttachment({ id: attachment.id, name: attachment.name });
                      onDeleteOpen();
                    }}
                  />
                </Tooltip>
              )}
            </HStack>
            {attachment.notes != null && (
              <Text fontSize="xs" color="gray.600" pl={4}>
                {attachment.notes}
              </Text>
            )}
          </VStack>
        ))}
        {attachments?.length === 0 && profileAttachment == null && <Text fontSize="sm">No attachments found</Text>}
        <Button size="sm" mt="4" maxW="60" color="white" background="gray.800" onClick={onOpen} alignSelf="flex-end">
          Add attachment
        </Button>
      </Flex>
      <AttachmentModal isOpen={isOpen} onClose={onClose} orgId={orgId} profileId={profileId} />
      {isDeleteOpen && (
        <ConfirmationModal
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          onConfirm={handleDelete}
          title="Delete Attachment"
          body={`Are you sure you want to delete the attachment "${selectedAttachment?.name}"?`}
        />
      )}
    </Heading>
  );
};

const ProfileOverview: React.FC = () => {
  const navigate = useNavigate();
  const { profileId } = useParams();
  const [updateProfile] = useUpdateProfileMutation();
  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const { data: profile, isLoading } = useGetProfileByIdQuery({
    orgId: activeOrgID,
    profileId
  });

  const { data: profileGroups = [] } = useGetProfileGroupsQuery({
    orgId: activeOrgID
  });

  const { data: inquiriesData } = useGetProfileInquiriesQuery({
    orgId: activeOrgID,
    profileId
  });
  const inquiries = inquiriesData?.results ?? [];

  const { data: countries = [] } = useGetCountriesQuery({});
  const { data: genders = [] } = useGetGendersQuery({});
  const { data: idTypes = [] } = useGetIdTypesQuery({});
  const { data: profileRiskRatings = [] } = useGetProfileRiskRatingsQuery({});
  const { data: natureOfEmployment = [] } = useGetNatureOfEmploymentQuery({});

  if (isLoading || profile == null) {
    return <Loading message="Loading Profile details..." />;
  }
  const mappedCountries = countries.map((country) => ({
    value: country.id,
    name: country.englishName
  }));

  const totalUnresolvedInquiries = inquiries.filter((inquiry) => inquiry.resolution === 'NO_RESOLUTION').length;
  const handleFieldChange = (name: string) => async (value) => {
    await updateProfile({
      orgId: activeOrgID,
      profileId,
      [name]: value
    }).unwrap();
  };
  const relatedPartiesStep = profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Related Parties');
  const relatedPartiesEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? relatedPartiesStep?.individual : relatedPartiesStep?.entity;

  const walletsStep = profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Add Wallets');
  const walletsEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? walletsStep?.individual : walletsStep?.entity;

  const expectedTransactionActivityStep = profile.profileGroup.stepsProfileForm.steps.find(
    ({ step }) => step === 'Expected Transaction Activity'
  );
  const expectedTransactionActivityEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL
      ? expectedTransactionActivityStep?.individual
      : expectedTransactionActivityStep?.entity;

  return (
    <>
      <Heading as="h1" size="lg" textAlign="center" mb={5}>
        Due Diligence Profile
      </Heading>
      <Flex justifyContent="center" alignItems="center" width="full" paddingX={5} mb={8}>
        <Box flex={1} />
        <Box display="flex" alignItems="center" flex={1} justifyContent="center">
          <Text mr={4} fontWeight={600}>
            Status
          </Text>
          <Select
            size="sm"
            borderRadius="full"
            variant="solid"
            colorScheme="yellow"
            value={profile?.status}
            onChange={async (e) => {
              await updateProfile({
                orgId: activeOrgID,
                profileId,
                status: e.target.value as any
              }).unwrap();
            }}
            width={200}
          >
            <option
              value={PROFILE_STATUS_CODE.ARCHIVED}
              disabled={
                !canChangeStatus(
                  profile?.status as PROFILE_STATUS_CODE,
                  PROFILE_STATUS_CODE.ARCHIVED,
                  profile?.hasOpenItems
                )
              }
            >
              Archived
            </option>
            <option
              value={PROFILE_STATUS_CODE.COMPLETE}
              disabled={
                !canChangeStatus(
                  profile?.status as PROFILE_STATUS_CODE,
                  PROFILE_STATUS_CODE.COMPLETE,
                  profile?.hasOpenItems
                )
              }
            >
              Complete
            </option>
            <option
              value={PROFILE_STATUS_CODE.DRAFT}
              disabled={
                !canChangeStatus(
                  profile?.status as PROFILE_STATUS_CODE,
                  PROFILE_STATUS_CODE.DRAFT,
                  profile?.hasOpenItems
                )
              }
            >
              Draft
            </option>
          </Select>
        </Box>
        <Box display="flex" flex={1} justifyContent="end">
          <Link to={`/dashboard/profile-wizard?profileId=${profileId}&step=1`}>
            <Button ml={4} leftIcon={<Icon as={FaMagic} />} color="white" bg="gray.800">
              Go to Wizard
            </Button>
          </Link>
        </Box>
      </Flex>
      {totalUnresolvedInquiries > 0 && (
        <Flex justifyContent="center" paddingX={5} width="100%">
          <AlertBox
            title={`Resolve ${totalUnresolvedInquiries} primary party screening${
              totalUnresolvedInquiries > 1 ? 's' : ''
            }`}
            buttonText="RESOLVE"
            onButtonClick={() => {
              navigate(`/dashboard/profiles/${profileId}/screenings`);
            }}
          />
        </Flex>
      )}

      <Flex justifyContent="space-between" pr={5} width="100%">
        <Box flex={2} pr={3}>
          <VStack spacing={4} align="stretch" m={4} p={4} boxShadow="md" bg="white" w="100%">
            <Flex justifyContent="space-between" width="full">
              <Heading as="h1" size="md" textAlign="left" mb={5}>
                Overview
              </Heading>
              <Text fontSize="sm" color="gray.500" fontStyle="italic">
                {profile != null && (
                  <>
                    Created at {formatDate(profile.createdAt)} | Update at: {formatDate(profile.updatedAt)}
                  </>
                )}
              </Text>
            </Flex>
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
              <EditableField label="Name" value={profile?.name} onConfirmChange={handleFieldChange('name')} />
              <EditableField
                label="Type"
                value={profile?.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Entity'}
                isDisabled={true}
                onConfirmChange={() => {}}
              />
              <Flex>
                <EditableField
                  label="Profile group"
                  value={profile?.profileGroup.id}
                  type="select"
                  options={profileGroups?.map((group) => ({
                    value: group.id,
                    name: group.name
                  }))}
                  onConfirmChange={handleFieldChange('profile_group')}
                />
                <ProfileGroupOverview profileGroupId={profile?.profileGroup.id} />
              </Flex>
              <EditableField
                label="Profile risk"
                value={profile?.risk}
                type="select"
                options={profileRiskRatings}
                onConfirmChange={handleFieldChange('risk')}
                allowNull={false}
              />
            </Grid>
          </VStack>
          <VStack
            spacing={4}
            align="stretch"
            m={4}
            p={4}
            boxShadow="md"
            bg="white"
            w="100%"
            border={totalUnresolvedInquiries > 0 ? '3px solid' : ''}
            borderColor={totalUnresolvedInquiries > 0 ? 'yellow.400' : ''}
            borderRadius="md"
          >
            <Flex justifyContent="space-between" width="full">
              <Heading as="h1" size="md" textAlign="left" mb={5}>
                Primary Party Information
              </Heading>
              <Link to={`/dashboard/profiles/${profileId}/screenings`}>
                <Button size="sm" color="white" background="gray.800">
                  Screening hits
                </Button>
              </Link>
            </Flex>
            {profile?.resourcetype === PROFILE_TYPE.INDIVIDUAL ? (
              <>
                <EditableField
                  label="Full legal name"
                  value={profile?.fullLegalName}
                  onConfirmChange={handleFieldChange('fullLegalName')}
                />
                <EditableField label="Email" value={profile?.email} onConfirmChange={handleFieldChange('email')} />
                <EditableField
                  label="Gender"
                  value={profile?.gender?.value}
                  onConfirmChange={handleFieldChange('gender')}
                  type="select"
                  options={genders}
                />
                <EditableField
                  label="Date of birth"
                  value={profile?.dateOfBirth}
                  onConfirmChange={handleFieldChange('dateOfBirth')}
                  type="date"
                />
                <EditableField
                  label="Citizenship"
                  value={profile?.citizenship?.id}
                  onConfirmChange={handleFieldChange('citizenship')}
                  type="select"
                  options={mappedCountries}
                />
                <EditableField
                  label="Country of Residence"
                  value={profile?.countryOfResidence?.id}
                  onConfirmChange={handleFieldChange('countryOfResidence')}
                  type="select"
                  options={mappedCountries}
                />
                <EditableField label="State" value={profile?.state} onConfirmChange={handleFieldChange('state')} />
                <EditableField label="City" value={profile?.city} onConfirmChange={handleFieldChange('city')} />
                <EditableField
                  label="Postal code"
                  value={profile?.postalCode}
                  onConfirmChange={handleFieldChange('postalCode')}
                />
                <EditableField
                  label="Address"
                  value={profile?.address}
                  onConfirmChange={handleFieldChange('address')}
                />
                <EditableField
                  label="Place of Birth"
                  value={profile?.placeOfBirth?.id}
                  onConfirmChange={handleFieldChange('placeOfBirth')}
                  type="select"
                  options={mappedCountries}
                />
                <EditableField
                  label="ID Issuer"
                  value={profile?.idIssuer?.id}
                  onConfirmChange={handleFieldChange('idIssuer')}
                  type="select"
                  options={mappedCountries}
                />
                <EditableField
                  label="ID type"
                  value={profile?.idType?.value}
                  onConfirmChange={handleFieldChange('idType')}
                  type="select"
                  options={idTypes}
                />
                <EditableField
                  label="ID number"
                  value={profile?.idNumber}
                  onConfirmChange={handleFieldChange('idNumber')}
                />
                <EditableField
                  label="Nature of employment"
                  value={profile?.natureOfEmployment}
                  onConfirmChange={handleFieldChange('natureOfEmployment')}
                  type="select"
                  options={natureOfEmployment}
                />
              </>
            ) : (
              <PrimaryPartyInformationEntity profile={profile} />
            )}
          </VStack>
          {expectedTransactionActivityEnabled === true && (
            <VStack spacing={4} align="stretch" m={4} p={4} boxShadow="md" bg="white" w="100%">
              <Flex justifyContent="space-between" width="full">
                <Heading as="h1" size="md" textAlign="left" mb={5}>
                  Expected Transaction Activity
                </Heading>
              </Flex>

              <>
                <EditableField
                  label="Purpose for establishing account"
                  value={profile?.purposeForEstablishingAccount}
                  onConfirmChange={handleFieldChange('purposeForEstablishingAccount')}
                />
                <EditableField
                  label="Expected nature of activity"
                  value={profile?.expectedNatureOfActivity}
                  onConfirmChange={handleFieldChange('expectedNatureOfActivity')}
                />
                <EditableField
                  label="Source of funds"
                  value={profile?.sourceOfFunds}
                  onConfirmChange={handleFieldChange('sourceOfFunds')}
                />
                <EditableField
                  label="Flow of funds"
                  value={profile?.flowOfFunds}
                  onConfirmChange={handleFieldChange('flowOfFunds')}
                />
                <EditableField
                  label="Activity type"
                  value={profile?.activityType}
                  onConfirmChange={handleFieldChange('activityType')}
                  type="select"
                  options={[
                    { value: 'one-time', name: 'One-time' },
                    { value: 'ongoing', name: 'Ongoing' }
                  ]}
                />
                <EditableField
                  label="Average monthly # transactions"
                  value={profile?.averageMonthlyTxn}
                  onConfirmChange={handleFieldChange('averageMonthlyTxn')}
                />
                <EditableField
                  label="Average monthly $ value txns"
                  value={profile?.averageMonthlyValueTxn}
                  onConfirmChange={handleFieldChange('averageMonthlyValueTxn')}
                />
                <EditableField
                  label="Expected transaction activity comments"
                  value={profile?.expectedTransactionActivityComments}
                  onConfirmChange={handleFieldChange('expectedTransactionActivityComments')}
                />
              </>
            </VStack>
          )}
        </Box>
        <Box flex={1} pr={2}>
          <VStack spacing={4} align="stretch" m={4} p={4} boxShadow="md" bg="white" w="100%">
            <ProfileNotes profileId={profileId} />
          </VStack>
          <VStack spacing={4} align="stretch" m={4} p={4} boxShadow="md" bg="white" w="100%">
            <Attachments
              profileId={profileId}
              profileAttachment={profile.attachment}
              attachments={profile.attachments}
              orgId={activeOrgID}
            />
          </VStack>
        </Box>
      </Flex>
      <Flex justifyContent="space-between" paddingX={4} width="100%" direction="column">
        {relatedPartiesEnabled === true && (
          <VStack spacing={4} align="stretch" mb={4} p={4} boxShadow="md" bg="white" w="100%">
            <Heading as="h1" size="md" textAlign="left" mb={5}>
              <Text mb="2">Related Parties</Text>
              <RelatedPartiesSection orgId={activeOrgID} profileId={profileId} />
            </Heading>
          </VStack>
        )}
        {walletsEnabled === true && (
          <VStack spacing={4} align="stretch" mb={4} p={4} boxShadow="md" bg="white" w="100%">
            <Heading as="h1" size="md" textAlign="left" mb={5}>
              <Text mb="2">Wallets</Text>
              <WalletsTable profileId={profileId} />
            </Heading>
          </VStack>
        )}
        <Box mt={4}>
          <ProfileAudit profileId={profileId} />
        </Box>
      </Flex>
    </>
  );
};

export default ProfileOverview;
