import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { Box, Flex, Button, Divider, Heading, VStack, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { ScreenedDataContainer } from './WatchlistScreening';
import DisplayField from './DisplayField';
import { useGetProfileInquiryByIdQuery, useUpdateProfileInquiryMutation } from '@services/canaria.services';
import Loading from '@features/loading/Loading.component';

const getPobs = (pobs: Array<{ address: string; country: { officialName: string }; city: string }>): string => {
  return pobs?.map((e) => [e.address, e.city, e.country?.officialName].filter(Boolean).join(' - ')).join('; ');
};

const getAddresses = (
  addresses: Array<{ address: string; country: { officialName: string }; state: string }>
): string => {
  return addresses?.map((e) => [e.address, e.state, e.country?.officialName].filter(Boolean).join(' - ')).join('; ');
};
const getDateFromString = (date: string): string[] => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8);
  return [day, month, year];
};
const getDatesOfBirth = (
  entries?: Array<{
    start: string;
    end: string;
    raw: string;
  }>
): string[] => {
  const dates: string[] = [];
  for (const entry of entries ?? []) {
    const ranges = [entry.start.slice(0, 10), entry.end.slice(0, 10)];
    if (ranges[0] === ranges[1]) {
      const [day, month, year] = getDateFromString(ranges[0]);
      dates.push(`${day}/${month}/${year}`);
    } else {
      const [startDay, startMonth, startYear] = getDateFromString(ranges[0]);
      const [endDay, endMonth, endYear] = getDateFromString(ranges[1]);
      dates.push(`${startDay}/${startMonth}/${startYear} - ${endDay}/${endMonth}/${endYear}`);
    }
  }
  return dates;
};

const CatchAllDisplay: React.FC<{
  catchAllData: Record<string, string>;
}> = ({ catchAllData }) => {
  const catchAllKeys = Object.keys(catchAllData ?? {});
  return (
    <>
      {catchAllKeys.map((key) => {
        return <DisplayField key={key} label={key} value={catchAllData[key]} />;
      })}
    </>
  );
};

const ProfileInquiryDetailsComponent: React.FC<{ profileInquiry }> = ({ profileInquiry }) => {
  return (
    <VStack spacing={4} align="stretch" m={4} p={4} boxShadow="md" bg="white">
      <Heading as="h1" size="md" textAlign="left" mb={5}>
        Hit Details
      </Heading>
      <DisplayField label="Profile Name" value={profileInquiry.resultName} />
      <DisplayField label="First Name" value={profileInquiry.result.listEntry.name.firstName} />
      <DisplayField label="Last Name" value={profileInquiry.result.listEntry.name.lastName} />
      <DisplayField
        label="Aliases"
        value={profileInquiry.result.listEntry.aliases?.map((e) => e.fullName).join('; ')}
      />
      <DisplayField label="Entry Type" value={profileInquiry.result.listEntry.type.name} />
      <DisplayField label="Date of Birth" value={getDatesOfBirth(profileInquiry.result.listEntry.dobs)?.join('; ')} />
      <DisplayField
        label="Citizenship"
        value={profileInquiry.result.listEntry.citizenships?.map((e) => e.official_name)?.join('; ')}
      />
      <DisplayField label="Place of Birth" value={getPobs(profileInquiry.result.listEntry.pobs)} />
      <DisplayField label="Addresses" value={getAddresses(profileInquiry.result.listEntry.addresses)} />
      <DisplayField
        label="List Source"
        value={`${profileInquiry.result.listEntry.listSource.name} - category: ${profileInquiry.result.listEntry.listSource.category.name}`}
      />
      <DisplayField
        label="List Names"
        value={profileInquiry.result.listEntry.listSource.lists.map((list) => list.name).join('; ')}
      />
      <Divider my={2} />
      <CatchAllDisplay catchAllData={profileInquiry.result.listEntry.catchAll} />
    </VStack>
  );
};

const ResolveHitComponent: React.FC<{
  resolution: string;
  notes: string;
  orgId: string;
  profileId: string;
  inquiryId: string;
}> = (params) => {
  const navigate = useNavigate();
  const [updateProfileInquiry] = useUpdateProfileInquiryMutation();

  const [resolution, setResolution] = useState<string>(params.resolution ?? '');
  const [notes, setNotes] = useState<string>(params.notes ?? '');

  const resolveHit = async (resolution: string, notes: string): Promise<void> => {
    await updateProfileInquiry({
      orgId: params.orgId,
      profileId: params.profileId,
      inquiryId: params.inquiryId,
      resolution,
      notes
    }).unwrap();
    navigate(-1);
  };

  const handleSubmit = async (): Promise<void> => {
    await resolveHit(resolution, notes);
  };

  return (
    <VStack spacing={4} m={4} p={4} boxShadow="md" bg="white" alignItems="start">
      <RadioGroup onChange={setResolution} value={resolution}>
        <Stack direction="row">
          <Radio value="FALSE_POSITIVE">False Positive</Radio>
          <Radio value="TRUE_MATCH">True Match</Radio>
        </Stack>
      </RadioGroup>
      <Input
        placeholder={`Describe why this is a ${resolution === 'FALSE_POSITIVE' ? 'false positive' : 'true match'}`}
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
      />
      <Flex justifyContent="end" w="100%">
        <Button colorScheme="blue" onClick={handleSubmit} isDisabled={resolution === '' || notes === ''}>
          Resolve Hit
        </Button>
      </Flex>
    </VStack>
  );
};

const ProfileInquiryOverview: React.FC = () => {
  const { profileId, inquiryId } = useParams();
  const orgId = useSelector(selectActiveOrgID);
  if (orgId == null) {
    throw new Error('activeOrgID is null');
  }
  if (inquiryId == null || profileId == null) {
    throw new Error('inquiryId or profileId are undefined');
  }

  const { data: profileInquiryData, isLoading: isProfileInquiryLoading } = useGetProfileInquiryByIdQuery({
    orgId,
    profileId,
    inquiryId
  });

  if (isProfileInquiryLoading) {
    return <Loading message="Loading screening data..." />;
  }
  return (
    <>
      <Flex justifyContent="end" pr={4}>
        <Link to={`/dashboard/profiles/${profileId}/screenings`}>
          <Button bg="black" color="white">
            Return
          </Button>
        </Link>
      </Flex>
      <Box>
        <ScreenedDataContainer />
        <ProfileInquiryDetailsComponent profileInquiry={profileInquiryData} />
        <ResolveHitComponent
          resolution={profileInquiryData.resolution}
          notes={profileInquiryData.notes}
          orgId={orgId}
          profileId={profileId}
          inquiryId={inquiryId}
        />
      </Box>
    </>
  );
};

export default ProfileInquiryOverview;
