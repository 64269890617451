import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface ErrorDisplayProps {
  statusCode: number;
  defaultMessage: string;
  message?: string;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ statusCode, defaultMessage, message }) => {
  return (
    <Box
      textAlign="center"
      py={10}
      px={6}
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg="white.100"
    >
      <Heading
        display="inline-block"
        as="h1"
        size="2xl"
        bgGradient="linear(to-r, teal.400, teal.600)"
        backgroundClip="text"
      >
        {statusCode ?? 'Error'}
      </Heading>
      <Text fontSize="xl" mt={3} mb={2}>
        {message ?? defaultMessage}
      </Text>
      <Button
        as={Link}
        to="/"
        mt={6}
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
      >
        Go back to Home
      </Button>
    </Box>
  );
};

export default ErrorDisplay;
