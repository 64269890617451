import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  useDisclosure,
  Heading,
  Container,
  Center,
  Input,
  InputGroup,
  Box,
  SimpleGrid,
  InputLeftElement,
  Button,
  Spinner,
  Text,
  Link
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { useSearchWalletsQuery, useGetWalletByAddressQuery } from '@services/canaria.services';
import { selectActiveOrgID } from '../../user-settings/userSlice';
import WalletCard from '../../wallets/walletCard.component';
import RegisterWalletModal from '../../wallets/registerWalletModal.component';

const ScreeningPanel: React.FC = () => {
  const [formState, setFormState] = useState({ q: '' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const searchParameters = { orgId: activeOrgID, walletQuery: formState.q };
  const [skipWalletSearch, setSkipWalletSearch] = useState<boolean>(true);
  const {
    data: searchData,
    error: searchError,
    isLoading: searchIsLoading,
    isUninitialized: searchIsUninitialized
  } = useSearchWalletsQuery(searchParameters, { skip: skipWalletSearch });

  const {
    data: getByAddressData,
    error: getByAddressError,
    isLoading: getByAddressIsLoading,
    isUninitialized: getByAddressIsUninitialized
  } = useGetWalletByAddressQuery(searchParameters, { skip: skipWalletSearch });

  const handleChange = ({ target: { name, value } }): void => {
    if (name === 'q' && value.length > 0) setSkipWalletSearch(false);
    else setSkipWalletSearch(true);
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      const searchWallet = document.getElementsByClassName('searchWallet')?.[0];
      (searchWallet as HTMLElement)?.click();
    }
  };

  let searchResult = <></>;
  let searchResultCount = <></>;

  if (!searchIsUninitialized) {
    if (searchIsLoading) {
      searchResult = <Spinner size="xl" />;
    } else if (searchData != null) {
      searchResultCount = (
        <Text pt={3}>
          Found {searchData.count} result{searchData.count > 1 ? 's' : ''}
        </Text>
      );
      searchResult = searchData.results.map((wallet) => <WalletCard key={wallet.id} wallet={wallet} />);
    } else if (searchError != null) {
      searchResult = <h1>Got errors :-(</h1>;
    }
  }

  let exactWalletResult = <></>;

  if (!getByAddressIsUninitialized) {
    if (getByAddressIsLoading) {
      exactWalletResult = <Spinner size="xl" />;
    } else if (getByAddressData != null) {
      if (getByAddressData.count === 1) {
        exactWalletResult = (
          <Text pt={3}>
            Wallet address already registered in system{' '}
            <Link as={RouterLink} color="teal.500" to={`/dashboard/wallets/${getByAddressData.results[0].id}`}>
              (Open wallet)
            </Link>
          </Text>
        );
      } else {
        exactWalletResult = (
          <Center pt={3}>
            <RegisterWalletModal isOpen={isOpen} onClose={onClose} address={formState.q} orgId={activeOrgID} />
            <SimpleGrid
              templateColumns="1fr 300px 100px 1fr"
              columns={4}
              spacing={10}
              gap={2}
              borderWidth={1}
              borderColor="black"
              pl={2}
              pr={2}
              pt={2}
              pb={2}
              rounded="lg"
            >
              <div></div>
              <div>
                <Text>Exact address for wallet not found. Register wallet address?</Text>
              </div>
              <Box display="flex" alignItems="center" justifyContent="center" textAlign="center">
                <Button className="searchWallet" size="sm" colorScheme="green" onClick={onOpen} bg="green.400">
                  Register
                </Button>
              </Box>
              <div></div>
            </SimpleGrid>
          </Center>
        );
      }
    } else if (getByAddressError != null) {
      exactWalletResult = <h1>Got errors :-(</h1>;
    }
  }
  return (
    <Container maxW="8xl">
      <Center>
        <Heading pb="20px" size="lg">
          Screening
        </Heading>
      </Center>
      <InputGroup bg="white" rounded="lg">
        <InputLeftElement>
          <FiSearch />
        </InputLeftElement>
        <Input
          placeholder="Search blockchain address, internal notes or previous inquiry results"
          onChange={handleChange}
          name="q"
          onKeyUp={handleKeyPress}
        />
      </InputGroup>
      {exactWalletResult}
      {searchResultCount}
      {searchResult}
    </Container>
  );
};

export default ScreeningPanel;
