import { Spinner, Flex, Text } from '@chakra-ui/react';

const Loading: React.FC<{ message?: string }> = ({ message }) => {
  return (
    <Flex width="100%" height="100%" alignItems="center" justifyContent="center" direction="column">
      {message != null && (
        <Text mb={4} fontSize="lg">
          {message}
        </Text>
      )}
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
    </Flex>
  );
};

export default Loading;
