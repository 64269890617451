import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Flex, Container, Button, Heading } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useGetProfilesQuery } from '@services/canaria.services';

import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, ThemeProvider, createTheme, Paper } from '@mui/material';
import { PROFILE_STATUS_DESCRIPTION, PROFILE_TYPE } from '@models/profileTypes';

interface ProfileTableRow {
  id: string;
  name: string;
  profileType: string;
  profileGroup: {
    name: string;
  };
  resourcetype: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const columns: Array<MRT_ColumnDef<ProfileTableRow>> = [
  {
    id: 'name',
    accessorKey: 'name',
    header: 'Name',
    Cell: ({ row }) => {
      return <Link to={`/dashboard/profiles/${row.original.id}`}>{row.original.name}</Link>;
    }
  },
  {
    id: 'profile_type',
    accessorKey: 'profileType',
    header: 'Type',
    Cell: ({ row }) => {
      return row.original.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Organization';
    }
  },
  {
    id: 'profile_group',
    accessorKey: 'profileGroup.name',
    header: 'Group'
  },
  {
    id: 'created_at',
    accessorKey: 'createdAt',
    header: 'Created',
    Cell: ({ row }) => {
      return dayjs(row.original.createdAt).format('YYYY-MM-DD hh:mm:ss A');
    }
  },
  {
    id: 'updated_at',
    accessorKey: 'updatedAt',
    header: 'Last Modified',
    Cell: ({ row }) => {
      return dayjs(row.original.updatedAt).format('YYYY-MM-DD hh:mm:ss A');
    }
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ row }) => {
      let bgColor;
      const status = row.original.status;
      switch (status) {
        case 'C':
          bgColor = 'green';
          break;
        case 'D':
          bgColor = 'orange';
          break;
        case 'N':
          bgColor = 'coral';
          break;
        case 'A':
          bgColor = 'grey';
          break;
        default:
          bgColor = 'black';
      }
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: bgColor,
            borderRadius: '0.25rem',
            color: '#fff',
            minWidth: '6ch',
            maxWidth: '14ch',
            p: '0.4rem'
          })}
        >
          <center>{PROFILE_STATUS_DESCRIPTION[status] ?? 'UNKNOWN STATUS'}</center>
        </Box>
      );
    }
  }
];

const defaultMaterialTheme = createTheme();

const Profiles: React.FC = () => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100
  });
  const { data, isLoading } = useGetProfilesQuery({
    orgId: activeOrgID,
    query: {
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ordering: sorting
        .map((s) => `${s.desc ? '-' : ''}${s.id}`)
        .join(',')
        .replaceAll('.', '__'),
      search: globalFilter,
      is_primary: true
    }
  });

  return (
    <Container maxW="8xl">
      <Heading as="h1" size="lg" textAlign="center" mb={5}>
        Profiles
      </Heading>
      <Flex justifyContent="flex-end" mx="4">
        <Button
          onClick={() => {
            navigate('/dashboard/profile-wizard');
          }}
          colorScheme="green"
          mb={4}
        >
          Create Profile
        </Button>
      </Flex>
      <ThemeProvider theme={defaultMaterialTheme}>
        <Paper
          elevation={2}
          style={{
            maxWidth: '100%',
            overflowX: 'auto',
            marginLeft: '1em',
            marginRight: '1em',
            paddingLeft: '1em'
          }}
        >
          <MaterialReactTable
            columns={columns}
            data={data?.results ?? []}
            initialState={{
              showGlobalFilter: true,
              showColumnFilters: false,
              density: 'compact'
            }}
            muiTableContainerProps={{
              sx: { maxHeight: 'calc(100vh - 96px)' }
            }}
            muiTablePaperProps={{
              elevation: 0
            }}
            muiPaginationProps={{
              rowsPerPageOptions: [100, 500],
              showFirstButton: false,
              showLastButton: false
            }}
            manualFiltering
            onGlobalFilterChange={setGlobalFilter}
            manualPagination
            onPaginationChange={setPagination}
            manualSorting
            onSortingChange={setSorting}
            state={{
              globalFilter,
              pagination,
              sorting,
              isLoading,
              columnOrder: columns.map((c) => c.id as string)
            }}
            rowCount={data?.count}
            enableColumnFilters={false}
          />
        </Paper>
      </ThemeProvider>
    </Container>
  );
};

export default Profiles;
