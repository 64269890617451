import { useState } from 'react';

import { Box, Button, HStack, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useProfileWizard } from '../../../context/ProfileWizardContext';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import {
  useCreateProfileMutation,
  useUpdateProfileMutation,
  useGetRelatedProfileQuery,
  useCreateRelatedProfileMutation,
  useUpdateRelatedProfileMutation
} from '@services/canaria.services';
import useQueryParams from '@hooks/useQueryParams';
import { AddPrimaryPartyForm } from '../individual/AddPrimaryParty';
import Loading from '@features/loading/Loading.component';
import RelatedPartyList from './RelatedProfileList';

interface ProfileFormProps {
  orgId: string;
  profile: IProfile;
}

const AddRelatedParty: React.FC<ProfileFormProps> = ({ orgId, profile }) => {
  const navigate = useNavigate();
  const { currentStep, maxSteps } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();

  const [showForm, setShowForm] = useState<boolean>(false);

  const AddRelatedPartyButton: React.FC = () => (
    <Button
      bg="black"
      color="white"
      onClick={() => {
        setShowForm(true);
      }}
    >
      Add related party
    </Button>
  );

  return (
    <Box w="90%">
      <Heading as="h1" size="lg" textAlign="center" mb="4">
        {currentStep}. Related Parties
      </Heading>
      <Box bg="white" p={4}>
        <RelatedProfileManager orgId={orgId} profile={profile} showForm={showForm} setShowForm={setShowForm}>
          <Box width="fit-content" marginX="auto" minH="36" mt="24">
            <HStack spacing={4}>
              <AddRelatedPartyButton />
              <Button
                colorScheme="gray"
                variant="outline"
                onClick={() => {
                  if (currentStep === maxSteps) {
                    navigate(`/dashboard/profiles/${profile.id}`);
                    return;
                  }
                  setQueryParams({ ...queryParams, step: currentStep + 1 });
                }}
              >
                Skip
              </Button>
            </HStack>
          </Box>
        </RelatedProfileManager>
        {!showForm && (
          <Flex justifyContent="center" gap={4} mt={4}>
            <Button
              bg="gray.300"
              onClick={() => {
                setQueryParams({ ...queryParams, step: currentStep - 1 });
              }}
            >
              Back
            </Button>
            <Button
              color="white"
              bg="black"
              onClick={() => {
                if (currentStep === maxSteps) {
                  navigate(`/dashboard/profiles/${profile.id}`);
                  return;
                }
                setQueryParams({ ...queryParams, step: currentStep + 1 });
              }}
            >
              {currentStep === maxSteps ? 'Finish' : 'Next'}
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

interface RelatedProfilesFormProps {
  orgId: string;
  profile: IProfile;
  showForm: boolean;
  setShowForm: (value: boolean) => void;
  children?: React.ReactNode;
}

export const RelatedProfileManager: React.FC<RelatedProfilesFormProps> = ({
  orgId,
  profile,
  showForm,
  setShowForm,
  children
}) => {
  const [createProfile] = useCreateProfileMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [createRelatedProfile] = useCreateRelatedProfileMutation();
  const [updateRelatedProfile] = useUpdateRelatedProfileMutation();

  const { data: dataRelatedProfiles, isLoading: isLoadingRelatedProfiles } = useGetRelatedProfileQuery({
    orgId,
    profileId: profile.id
  });

  const [currentRelatedProfile, setCurrentRelatedProfile] = useState<{
    id: string;
    relationType: {
      value: string;
      name: string;
    };
    profile: IProfile;
  } | null>(null);

  if (isLoadingRelatedProfiles) {
    return <Loading />;
  }

  const relatedProfiles = dataRelatedProfiles?.results;

  const handleAddRelatedProfile = async (relatedProfile): Promise<void> => {
    let relatedProfileId = relatedProfile.id;
    if (currentRelatedProfile == null) {
      // We create a new profile first with basic information
      const relatedProfileCreated = await createProfile({
        orgId,
        name: relatedProfile.fullLegalName,
        profileGroup: profile.profileGroup.id,
        resourcetype: relatedProfile.profileType,
        // Since it's a related party, we set isPrimary:false
        isPrimary: false
      }).unwrap();

      relatedProfileId = relatedProfileCreated.id;
      await createRelatedProfile({
        orgId,
        profileId: profile.id,
        toProfile: relatedProfileId,
        relationType: relatedProfile.relationType
      }).unwrap();
    } else {
      await updateRelatedProfile({
        orgId,
        profileId: profile.id,
        relatedProfileId: currentRelatedProfile.id,
        relationType: relatedProfile.relationType
      }).unwrap();
    }
    if (relatedProfile.profileType === PROFILE_TYPE.INDIVIDUAL) {
      await updateProfile({
        orgId,
        profileId: relatedProfileId,
        address: relatedProfile.address,
        citizenship: relatedProfile.citizenship,
        city: relatedProfile.city,
        postalCode: relatedProfile.postalCode,
        countryOfResidence: relatedProfile.countryOfResidence,
        dateOfBirth: relatedProfile.dateOfBirth,
        email: relatedProfile.email,
        fullLegalName: relatedProfile.fullLegalName,
        gender: relatedProfile.gender,
        idIssuer: relatedProfile.idIssuer,
        idNumber: relatedProfile.idNumber,
        idType: relatedProfile.idType,
        natureOfEmployment: relatedProfile.natureOfEmployment,
        placeOfBirth: relatedProfile.placeOfBirth,
        state: relatedProfile.state
      }).unwrap();
    } else {
      await updateProfile({
        orgId,
        profileId: relatedProfileId,
        fullLegalName: relatedProfile.fullLegalName,
        dbaTradeName: relatedProfile.dbaTradeName,
        entityType: relatedProfile.entityType,
        placeOfEstablishment: relatedProfile.placeOfEstablishment,
        dateOfEstablishment: relatedProfile.dateOfEstablishment,
        uniqueIdentificationNumber: relatedProfile.uniqueIdentificationNumber,
        registeredBusinessAddress: relatedProfile.registeredBusinessAddress,
        mailingAddress: relatedProfile.mailingAddress,
        physicalAddress: relatedProfile.physicalAddress,
        companyWebsite: relatedProfile.companyWebsite
      });
    }

    setCurrentRelatedProfile(null);
    setShowForm(false);
  };

  if (showForm) {
    return (
      <>
        <AddPrimaryPartyForm
          profile={currentRelatedProfile?.profile}
          onSubmit={handleAddRelatedProfile}
          isRelatedParty={true}
          relationType={currentRelatedProfile?.relationType?.value}
        />
        <Box width="fit-content" marginX="auto" mt={4}>
          <HStack spacing={4}>
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={() => {
                setShowForm(false);
                setCurrentRelatedProfile(null);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" bg="black" color="white" form="IndividualPrimaryParty">
              {currentRelatedProfile != null ? 'Update' : 'Add Related Party'}
            </Button>
          </HStack>
        </Box>
      </>
    );
  }

  return (
    <>
      {relatedProfiles?.length === 0 ? (
        children
      ) : (
        <RelatedPartyList
          isLoadingRelatedProfiles={isLoadingRelatedProfiles}
          relatedProfiles={relatedProfiles}
          onClickEdit={(relatedProfile) => {
            setShowForm(true);
            setCurrentRelatedProfile(relatedProfile);
          }}
          onClickAdd={() => {
            setShowForm(true);
          }}
        />
      )}
    </>
  );
};

export default AddRelatedParty;
