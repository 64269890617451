export interface ProfileGroup {
  name: string;
  description: string;
}

export interface IExpectedActivity {
  purposeForEstablishingAccount: string;
  expectedNatureOfActivity: string;
  sourceOfFunds: string;
  flowOfFunds: string;
  activityType: 'one-time' | 'ongoing';
  averageMonthlyTxn: string;
  averageMonthlyValueTxn: string;
  expectedTransactionActivityComments: string;
}

export interface IRootProfile extends IExpectedActivity {
  id: string;
  name: string;
  profileGroup: {
    id: string;
    name: string;
    stepsProfileForm: {
      steps: Array<{
        step: 'Related Parties' | 'Business Regulatory Compliance' | 'Expected Transaction Activity' | 'Add Wallets';
        entity: boolean;
        individual: boolean;
      }>;
    };
  };
  relatedProfiles: any[];
  status: 'A' | 'C' | 'D';
  resourcetype: PROFILE_TYPE;
  risk: string;
  hasOpenItems: boolean;
  attachments: Array<{
    id: string;
    file: string;
    name: string;
    notes: string;
    createdAt: string;
  }>;
  createdAt: string;
  updatedAt: string;
}

export interface IIndividualProfile {
  id: string;
  fullLegalName: string;
  email: string;
  gender: {
    value: string;
    name: string;
  };
  dateOfBirth: string;
  address: string;
  state: string;
  city: string;
  postalCode: string;
  idNumber: string;
  idType: {
    value: string;
    name: string;
  };
  natureOfEmployment: string;
  citizenship: {
    id: number;
    englishName: string;
  };
  countryOfResidence: {
    id: number;
    englishName: string;
  };
  idIssuer: {
    id: number;
    englishName: string;
  };
  placeOfBirth: {
    id: number;
    englishName: string;
  };
  attachment?: {
    file: string;
    createdAt: string;
  };
}

export interface IEntityProfile {
  fullLegalName: string;
  dbaTradeName: string;
  entityType: string;
  placeOfEstablishment: {
    id: number;
    englishName: string;
  };
  dateOfEstablishment: string;
  uniqueIdentificationNumber: string;
  registeredBusinessAddress: string;
  mailingAddress: string;
  physicalAddress: string;
  companyWebsite: string;
  natureOfBusiness: string;
  highRiskJurisdictionFootprint: Array<{
    id: number;
    englishName: string;
  }>;
  regulatoryInvestigationStatus: string;
  licensingRegistrationStatus: string;
  licensingRegistrationInformation: string;
  amlProgramRequired: boolean;
  amlProgramElements: string;
  amlProgramComments: string;
  sanctionsProgramRequired: boolean;
  sanctionsProgramElements: string;
  sanctionsProgramComments: string;
}

export type IProfile = IRootProfile & IIndividualProfile & IEntityProfile;

type IUpdateEntityProfile = Partial<IEntityProfile>;

type IUpdateExpectedActivity = Partial<IExpectedActivity>;

export type IUpdateProfile = {
  name?: string;
  profileGroup?: string;
  risk?: string;
  status?: 'A' | 'C' | 'D' | 'N';
  fullLegalName?: string;
  email?: string;
  gender?: string;
  dateOfBirth?: string;
  citizenship?: string;
  state?: string;
  city?: string;
  address?: string;
  placeOfBirth?: string;
  postalCode?: string;
  countryOfResidence?: string;
  idIssuer?: string;
  idType?: string;
  idNumber?: string;
  natureOfEmployment?: string;
} & IUpdateExpectedActivity &
  IUpdateEntityProfile;

export enum PROFILE_STATUS_DESCRIPTION {
  A = 'Archived',
  C = 'Complete',
  D = 'Draft'
}

export enum PROFILE_STATUS_CODE {
  ARCHIVED = 'A',
  COMPLETE = 'C',
  DRAFT = 'D'
}

export enum PROFILE_TYPE {
  INDIVIDUAL = 'IndividualProfile',
  ENTITY = 'EntityProfile'
}
