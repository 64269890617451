import { useState } from 'react';
import { Flex, Heading, Container, Input, Button, VStack, Text, Box } from '@chakra-ui/react';
import { useGetProfileGroupsQuery, useCreateProfileGroupMutation } from '@services/canaria.services';
import { useSelector } from 'react-redux';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import Loading from '@features/loading/Loading.component';
import { type ProfileGroup } from '@models/profileTypes';

const AdminProfileGroups: React.FC = () => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const { data: fetchedProfileGroups, isLoading, error } = useGetProfileGroupsQuery({ orgId: activeOrgID });
  const [newGroup, setNewGroup] = useState<ProfileGroup>({ name: '', description: '' });
  const [createProfileGroup, { isLoading: isCreating }] = useCreateProfileGroupMutation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewGroup({ ...newGroup, [e.target.name]: e.target.value });
  };

  const addProfileGroup = async (): Promise<void> => {
    try {
      await createProfileGroup({ ...newGroup, orgId: activeOrgID }).unwrap();
      setNewGroup({ name: '', description: '' });
    } catch (error) {
      console.error('Failed to create profile group', error);
    }
  };

  return (
    <Container maxW="container.md">
      <VStack spacing={4}>
        <Heading as="h1" size="lg" textAlign="center" mb={5} fontSize="2xl">
          Profile Groups
        </Heading>
        <Box bg="white" p={5} shadow="md" borderWidth="1px" borderRadius="md">
          <Input placeholder="Name" value={newGroup.name} onChange={handleInputChange} name="name" />
          <Input
            placeholder="Description"
            value={newGroup.description}
            onChange={handleInputChange}
            name="description"
            mt={2}
          />
          <Button onClick={addProfileGroup} mt={2} isLoading={isCreating} alignSelf="flex-end">
            Add Profile Group
          </Button>
        </Box>
        <Flex direction="column" align="stretch">
          {isLoading ? (
            <Loading />
          ) : error != null ? (
            <Text>Error fetching profile groups</Text>
          ) : (
            fetchedProfileGroups.map((group, index) => (
              <Box key={index} p={2} shadow="md" borderWidth="1px">
                <Text fontWeight="bold">{group.name}</Text>
                <Text>{group.description}</Text>
              </Box>
            ))
          )}
        </Flex>
      </VStack>
    </Container>
  );
};

export default AdminProfileGroups;
