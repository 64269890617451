import { Link as RouterLink } from 'react-router-dom';
import { Card, CardHeader, CardBody, Text, Stack, Link, Box, Heading, StackDivider } from '@chakra-ui/react';
import type { IWallet } from '@models/walletTypes';

const WalletCard: React.FC<{ wallet: IWallet }> = (props: { wallet: IWallet }) => {
  return (
    <Card mt={10}>
      <CardHeader>
        <Link as={RouterLink} to={`/dashboard/wallets/${props.wallet.id}`}>
          <Heading size="md">Wallet Details</Heading>
        </Link>
      </CardHeader>
      <CardBody>
        <Stack divider={<StackDivider />} spacing="1">
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Wallet Name
            </Heading>
            <Text pt="2" fontSize="sm">
              {props.wallet.name}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Wallet Address
            </Heading>
            <Text pt="2" fontSize="sm">
              {props.wallet.address}
            </Text>
          </Box>
          <Box>
            <Heading size="xs" textTransform="uppercase">
              Wallet Notes
            </Heading>
            <Text pt="2" fontSize="sm">
              {props.wallet.notes ?? 'None'}
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default WalletCard;
