import { Box, Button, Icon, Heading } from '@chakra-ui/react';
import { SearchIcon, WarningTwoIcon } from '@chakra-ui/icons'; // Ensure you have this icon in your icons library

const CompositeIcon: React.FC = () => {
  return (
    <Box position="absolute" boxSize={10}>
      <Icon
        as={SearchIcon}
        position="absolute"
        right="-1"
        bottom="0.5"
        color="transparent"
        boxSize="10"
        stroke="black"
        strokeWidth="0.5"
      />
      <Icon
        as={WarningTwoIcon}
        position="absolute"
        left="0.5"
        top="2"
        color="yellow.400"
        stroke="black"
        strokeWidth="0.7"
        boxSize="8"
      />
    </Box>
  );
};

const AlertBox: React.FC<{
  title: string;
  buttonText: string;
  onButtonClick: () => void;
}> = ({ title, buttonText, onButtonClick }) => {
  return (
    <Box
      p="6"
      borderWidth="2px"
      borderColor="yellow.500"
      borderRadius="md"
      bg="yellow.400"
      boxShadow="md"
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxH={200}
      maxW={350}
    >
      <Box minHeight={10} minW={10}>
        <CompositeIcon />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" p={4}>
        <Heading as="h2" size="md" color="gray.800" textAlign="center" mb={4}>
          {title}
        </Heading>
        <Button bg="gray.800" color="white" zIndex="banner" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default AlertBox;
